<template>
  <div class="success">
    <img style="width:45px;height:45px;margin-bottom: 10px;margin-top: 40px;"
         src="@/assets/images/success2.png"
         alt="">
    <div style="color: #202122;font-size:14px">支付成功</div>
    <div class="btn"
         @click="showTakeDialog = true">查看优惠券</div>
    <img @click="toDownload"
         style="width:100%"
         src="@/assets/images/2976.png"
         alt="">
    <van-dialog v-model="showTakeDialog"
                :show-confirm-button="false">
      <div class="dialog">
        <p class="dialog-title">提示</p>
        <p style="padding-bottom: 10px">
          请前往APP经销商身份的<span style="color: #00a0ac">我的-优惠券</span>进行查看
        </p>
        <div class="dialog-footer">
          <div class="dialog-button dialog-cancel-button"
               @click="showTakeDialog = false">
            取消
          </div>
          <div class="dialog-button"
               @click="toDownload">下载APP</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      showTakeDialog: false
    };
  },
  watch: {},
  computed: {},
  methods: {
    toDownload () {
      this.$router.push({
        name: 'Download'
      })
    },
  },
  created () {
    document.title = '支付结果'
  },
  mounted () { }
};
</script>
<style lang="scss" scoped>
.success {
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn {
    width: 86px;
    height: 26px;
    text-align: center;
    color: #333333;
    font-size: 15px;
    line-height: 26px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .dialog {
    text-align: center;
    padding: 20px;
    color: #505255;
    font-size: 14px;
    line-height: 1;

    .dialog-icon {
      height: 30px;
      width: 30px;
      margin-bottom: 10px;
    }

    .dialog-title {
      font-size: 18px;
      padding-bottom: 18px;
      color: #202122;
    }

    .dialog-footer {
      display: flex;
      padding-top: 18px;

      .dialog-button {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #fff;
        background: #00a0ac;
        border-radius: 4px;
        flex: 1;
      }

      .dialog-cancel-button {
        color: #505255;
        background: #f3f4f6;
        margin-right: 10px;
      }
    }
  }
}
</style>